var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Responses"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "indigo lighten-1",
                text: "",
                to: "majority-vote-table"
              }
            },
            [_vm._v("Majority Vote Table")]
          ),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "mdi-magnify",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.searchStr,
              callback: function($$v) {
                _vm.searchStr = $$v
              },
              expression: "searchStr"
            }
          })
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.table.headers,
          items: _vm.table.rows,
          "items-per-page": 50,
          "footer-props": { itemsPerPageOptions: [10, 30, 50, 100, 300] },
          search: _vm.searchStr
        },
        scopedSlots: _vm._u([
          {
            key: "item.Answers",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-simple-table", {
                  attrs: { dense: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c(
                              "tbody",
                              _vm._l(item.Answers, function(value, key) {
                                return _c("tr", { key: key }, [
                                  _c(
                                    "td",
                                    { staticStyle: { width: "100px" } },
                                    [_c("b", [_vm._v(_vm._s(key))])]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: { "word-break": "break-all" }
                                    },
                                    [_vm._v(_vm._s(value))]
                                  )
                                ])
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }